<template>
<!-- 门店信息同步 -->
  <div class="body">
      <div class="header">
          <el-form ref="form" :model="form" label-width="120px">
              <el-form-item label="供应商别名" class="itemBox">
                  <el-input v-model.trim="form.supplyName"></el-input>
              </el-form-item>
              <el-form-item label="供应商/门店名称" class="itemBox">
                  <el-input v-model.trim="form.supplyStoreName"></el-input>
              </el-form-item>
              <el-button type="primary" class="itemBox" @click="getMessage">查询</el-button>
              <el-button type="primary" @click="equalMesg()" class="itemBox">一键同步</el-button>
          </el-form>
          
      </div>
      <div class="tableBox">
          <el-table :data="tableData">
              <el-table-column prop="supplyName" label="供应商别名" width="180"></el-table-column>
              <el-table-column prop="supplyStoreId" label="供应商门店ID" width="180"></el-table-column>
              <el-table-column prop="supplyStoreName" label="供应商门店名称" width="180"></el-table-column>
              <el-table-column prop="supplyStoreId" label="丽日购门店ID" width="180"></el-table-column>
              <el-table-column prop="storeName" label="丽日购门店名" width="180"></el-table-column>
              <el-table-column prop="sync" label="同步标识" width="180">
                  <template slot-scope="scope">
                    <el-switch
                     v-model="scope.row.sync" 
                     active-text="同步" inactive-text="未同步" 
                     active-color='#13ce66' 
                     inactive-color="#ff4949"
                     active-value="1"
                     inactive-value="0"
                     disabled
                     ></el-switch>
                 </template>
              </el-table-column>
              <el-table-column prop="updateTime" label="更新时间" width="180">
                  <!-- <template slot-scope="scope">
                      <span>{{getUpdate(scope.row.timestamp)}}</span>
                  </template> -->
              </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 50, 100, 200]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalSize">
          </el-pagination>
      </div>
  </div>
</template>
<script>
import axios from "axios";
import {pageStoreMapping,updateStoreMapping,syncStoreMapping} from '@/api/config_center/equalGoodsMsg';
import Axios from 'axios';
export default {
    data(){
        return {
            form:{
               supplyName:"",
               supplyStoreName:"",
            },
            tableData:[],
            pageNum:1,
            pageSize:10,
            totalSize:100,
            baseURL: process.env.VUE_APP_BASE_API
        }
    },
    mounted() {
        this.getMessage()
    },
    methods:{
        handleSizeChange(e){
            console.log(e)
            this.pageSize = e
            this.getMessage()
        },
        handleCurrentChange(e){
            console.log(e)
            this.pageNum = e
            this.getMessage()
        },
        // 查询展示
        getMessage(){
            let param = {
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                supplyName:this.form.supplyName,
                supplyStoreName:this.form.supplyStoreName,
            }
            axios.post(this.baseURL+'/small-inteface/goods-service/techfuser/pageStoreMapping',param).then(res=>{
                this.tableData = res.data.body.list
            }).catch(err=>{
                console.log(err)
            }).finally(()=>{
                console.log('函数执行结束')
            })
        },
        // 同步状态
        equalMesg(){
            let param = {
                // pageNum:this.pageNum,
                // pageSize:this.pageSize,
                // supplyName:this.form.supplyName,
                // supplyStoreName:this.form.supplyStoreName,
            }
            axios.post(this.baseURL+'/small-inteface/goods-service/techfuser/syncStoreMapping',param).then(res=>{
                // this.tableData = res.data.body.list
                this.$message({
                    message: '同步更新成功',
                    type: 'success'
                });
            }).catch(err=>{
                this.$message({
                    message: '同步更新失败',
                    type: 'error'
                });
                console.log(err)
            }).finally(()=>{
                console.log('函数执行结束')
            })
        },
        getUpdate(e){
            let yyyy = e.getFullYear()
            let MM = e.getMonth()+1
            let dd = e.getDate()
            let mm = e.getMinutes()
            let hh = e.getHours()
            let ss = e.getSeconds()
            let time = yyyy+'年'+MM+'月'+dd+'日'+" "+mm+':'+hh+':'+ss
            console.log(time)
            return time
        }
    },
}
</script>
<style lang="scss" scoped>
.itemBox{
    // width: 500px;
    float: left;
    margin:  12px 12px;
}
</style>